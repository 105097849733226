import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { AppComponent } from '@app-costumer/app.component';
import { appRoutes } from '@app-costumer/app.routing';
import { appConfig } from '@app-costumer/core/config/app.config';
import { CoreModule } from '@app-costumer/core/core.module';
import { LayoutModule } from '@app-costumer/layout/layout.module';
import { FuseModule } from "@lib-fuse/fuse.module";
import { FuseConfigModule } from '@lib-fuse/services/config';


const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
