import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CostumerAuthService } from "@app-costumer/core/auth/costumer-auth.service";
import { AuthModule } from "@auth0/auth0-angular";
import { environment } from "../../../environments/environment";

@NgModule({
    providers: [
        CostumerAuthService,
    ],
    imports: [
        HttpClientModule,
        AuthModule.forRoot({
            domain: environment.auth.domain,
            clientId: environment.auth.clientId,
            audience:environment.client.httpHost,
            httpInterceptor: {
                allowedList: [
                    {
                        uri: environment.client.httpHost + "/*",
                        tokenOptions: {
                            audience: environment.client.httpHost,
                            redirect_uri: new URL(window.location.href).origin + "/signed-in-redirect",
                        }
                    }
                ]
            }
        }),
    ]
})
export class CostumerAuthModule {
}
