import { HttpBackend, HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable, InjectionToken, Injector } from "@angular/core";
import { Observable } from "rxjs";

export const HTTP_HOST = new InjectionToken<string>('HTTP_HOST');
export const WS_HOST = new InjectionToken<string>('WS_HOST');
export const CLIENT_HTTP_INTERCEPTOR = new InjectionToken<string>('AUTH_INTERCEPTOR');

@Injectable()
export class LibPrivateHttpHostInterceptor implements HttpInterceptor {
    constructor(@Inject(HTTP_HOST) private _httpHost: string) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request.clone({
                url: this._httpHost + request.url
            })
        );
    }
}

export class LibPrivateClientInterceptorHandler implements HttpHandler {
    constructor(private _next: HttpHandler, private _interceptor: HttpInterceptor) {}

    handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
        return this._interceptor.intercept(req, this._next);
    }
}

export class LibPrivateClientHttpHandler implements HttpHandler {
    private _chain: HttpHandler | null = null;

    constructor(private _backend: HttpBackend, private _injector: Injector){}

    handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
        if (this._chain === null) {
            const interceptors = this._injector.get(CLIENT_HTTP_INTERCEPTOR, []);
            this._chain = interceptors.reduceRight((next, interceptor) =>
                new LibPrivateClientInterceptorHandler(next, interceptor), this._backend);
        }
        return this._chain.handle(req);
    }
}

@Injectable({
    providedIn: 'root'
})
export class LibPrivateClientHttp extends HttpClient {
    constructor(private _backend: HttpBackend, private _injector: Injector) {
        super(new LibPrivateClientHttpHandler(_backend, _injector));
    }
}
