import { Inject, Injectable, InjectionToken, Injector } from "@angular/core";
import { first, Observable, Observer, of, Subject } from "rxjs";
import { AnonymousSubject } from "rxjs/internal/Subject";

export const WEBSOCKET_HOST = new InjectionToken<string>('WEBSOCKET_HOST');
export const WEBSOCKET_AUTH_INTERCEPTOR = new InjectionToken<string>('WEBSOCKET_AUTH_INTERCEPTOR');

export interface WebsocketAuthInterceptor {
    intercept(url: URL, next: WebsocketAuthHandler): Observable<URL>;
}

export abstract class WebsocketAuthHandler {
    abstract handle(url: URL): Observable<URL>;
}

@Injectable()
export abstract class WebsocketAuthBackend implements WebsocketAuthHandler {
    abstract handle(url: URL): Observable<URL>
}

export class DefaultWebsocketAuthBacked extends WebsocketAuthBackend {
    handle(url: URL): Observable<URL> {
        return of(url)
    }
}

export class WebsocketAuthInterceptorHandler implements WebsocketAuthHandler {
    constructor(private _next: WebsocketAuthHandler, private _interceptor: WebsocketAuthInterceptor) {
    }

    handle(url: URL): Observable<URL> {
        return this._interceptor.intercept(url, this._next);
    }
}

@Injectable()
export class WebsocketClient {
    private _connection: WebSocket | null = null;
    private _chain: WebsocketAuthHandler | null = null;
    private _subject: AnonymousSubject<MessageEvent>;
    public events: Subject<any>;

    constructor(
        @Inject(WEBSOCKET_HOST) private _wsHost: string,
        private _backend: WebsocketAuthBackend,
        private _injector: Injector,
    ) {
    }

    public connect(): Observable<void> {
        return new Observable<void>(subscriber => {
            if (this._connection != null) {
                subscriber.next();
                subscriber.complete();
                return;
            }
            if (this._chain === null) {
                const interceptors = this._injector.get(WEBSOCKET_AUTH_INTERCEPTOR, []);
                this._chain = interceptors.reduceRight((next, interceptor) =>
                        new WebsocketAuthInterceptorHandler(next, interceptor),
                    this._backend,
                );
            }

            this._chain.handle(new URL(this._wsHost)).pipe(first()).subscribe(url => {
                this._subject = this._connect(url)
                this.events = this._subject;
                subscriber.next();
                subscriber.complete();
            });
        })
    }

    private _connect(url: URL): AnonymousSubject<MessageEvent> {
        this._connection = new WebSocket(url)
        let observable = new Observable((obs: Observer<MessageEvent>) => {
            this._connection.onmessage = obs.next.bind(obs);
            this._connection.onerror = obs.error.bind(obs);
            this._connection.onclose = obs.complete.bind(obs);
            return this._connection.close.bind(this._connection);
        });
        let observer = {
            error: null,
            complete: null,
            next: (data: Object) => {
                // Interceptor here?
                if (this._connection.readyState === WebSocket.OPEN) {
                    this._connection.send(JSON.stringify(data));
                }
            }
        };
        return new AnonymousSubject<MessageEvent>(observer, observable);
    }

}