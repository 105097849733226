import { Inject, Injectable, Injector } from "@angular/core";
import { WEBSOCKET_HOST, WebsocketAuthBackend, WebsocketClient } from "@lib-websocket/lib-websocket-client";

@Injectable({
    providedIn: 'root'
})
export class LibCostumerClientWs extends WebsocketClient {
    constructor(
        @Inject(WEBSOCKET_HOST) _wsHost: string,
        _backend: WebsocketAuthBackend,
        _injector: Injector) {
        super(_wsHost, _backend, _injector);
    }
}