import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import {
    DefaultWebsocketAuthBacked,
    WEBSOCKET_AUTH_INTERCEPTOR,
    WEBSOCKET_HOST,
    WebsocketAuthBackend,
    WebsocketAuthInterceptor,
    WebsocketClient
} from "./lib-websocket-client";


export interface LibWebsocketModuleParams {
    host: string,
    authInterceptor: Type<WebsocketAuthInterceptor>
}


@NgModule({
    providers: [
        WebsocketClient,
        {
            provide: WebsocketAuthBackend,
            useValue: new DefaultWebsocketAuthBacked(),
        }
    ],
})
export class LibWebsocketModule {
    static forRoot(params: LibWebsocketModuleParams): ModuleWithProviders<LibWebsocketModule> {
        return {
            ngModule: LibWebsocketModule,
            providers: [
                {provide: WEBSOCKET_AUTH_INTERCEPTOR, useClass: params.authInterceptor, multi: true},
                {provide: WEBSOCKET_HOST, useValue: params.host},
            ]
        }
    }
}
