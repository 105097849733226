import { HttpInterceptor } from "@angular/common/http";
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { LibPrivateHttpHostInterceptor } from "@lib-private-client/http-client";
import {
    WEBSOCKET_AUTH_INTERCEPTOR,
    WEBSOCKET_HOST,
    WebsocketAuthInterceptor
} from "@lib-websocket/lib-websocket-client";
import { LibWebsocketModule } from "@lib-websocket/lib-websocket.module";
import { CLIENT_HTTP_INTERCEPTOR, HTTP_HOST, LibCostumerHttpHostInterceptor } from "./http-client";


export interface LibCostumerClientModuleParams {
    http: {
        host: string,
        interceptor: Type<HttpInterceptor>
    },
    ws: {
        host: string,
        authInterceptor: Type<WebsocketAuthInterceptor>
    }
}

@NgModule({
    imports: [
        LibWebsocketModule,
    ]
})
export class LibCostumerClientModule {
    static forRoot(params: LibCostumerClientModuleParams): ModuleWithProviders<LibCostumerClientModule> {
        return {
            ngModule: LibCostumerClientModule,
            providers: [
                {provide: HTTP_HOST, useValue: params.http.host},
                {provide: CLIENT_HTTP_INTERCEPTOR, useClass: LibPrivateHttpHostInterceptor, multi: true},
                {provide: CLIENT_HTTP_INTERCEPTOR, useClass: params.http.interceptor, multi: true},
                {provide: WEBSOCKET_HOST, useValue: params.ws.host},
                {provide: WEBSOCKET_AUTH_INTERCEPTOR, useClass: params.ws.authInterceptor, multi: true}
            ]
        }
    }
}
