import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppConfig, Scheme, } from "@app-costumer/core/config/app.config";
import { FuseConfigService } from "@lib-fuse/services/config";
import { Subject, takeUntil } from "rxjs";

@Component({
    selector: 'toggle-theme',
    templateUrl: './toggle-theme.component.html',
    styleUrls: ['./toggle-theme.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleThemeComponent implements OnInit {
    config: AppConfig;
    scheme: 'dark' | 'light';
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private _fuseConfigService: FuseConfigService) {
    }

    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: AppConfig) => {

                // Store the config
                this.config = config;
            });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    /**
     * Set the scheme on the config
     *
     * @param scheme
     */
    setScheme(scheme: Scheme): void {
        this._fuseConfigService.config = {scheme};
    }
}
