import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Navigation } from '@app-costumer/core/navigation/navigation.types';
import { FuseNavigationItem } from "@lib-fuse/components/navigation";
import { Observable, of, ReplaySubject } from 'rxjs';


const defaultNavigation: FuseNavigationItem[] = [];
const compactNavigation: FuseNavigationItem[] = [
    ...defaultNavigation
];
const futuristicNavigation: FuseNavigationItem[] = [
    ...defaultNavigation
];
const horizontalNavigation: FuseNavigationItem[] = [
    ...defaultNavigation
];

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    constructor(private _httpClient: HttpClient) {
    }

    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    get(): Observable<Navigation> {
        return new Observable<Navigation>((subscriber) => {
            let out = {
                compact: compactNavigation,
                default: defaultNavigation,
                futuristic: futuristicNavigation,
                horizontal: horizontalNavigation
            }
            this._navigation.next(out)
            subscriber.next(out)
            subscriber.complete()
        })
    }
}
