import { Injectable, NgModule, Optional, SkipSelf } from '@angular/core';
import { CostumerAuthModule } from "@app-costumer/core/auth/costumer-auth.module";
import { IconsModule } from '@app-costumer/core/icons/icons.module';
import { TranslocoCoreModule } from '@app-costumer/core/transloco/transloco.module';
import { AuthHttpInterceptor, AuthModule, AuthService } from "@auth0/auth0-angular";
import { LibCostumerClientModule } from "@lib-costumer-client/client.module";
import { WebsocketAuthInterceptor } from "@lib-websocket/lib-websocket-client";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable()
class CostumerWsAuthInterceptor implements WebsocketAuthInterceptor {
    constructor(private _auth0: AuthService) {
    }

    intercept(url: URL): Observable<URL> {
        return new Observable<URL>(subscriber => {
            this._auth0.getAccessTokenSilently({
                audience: environment.client.httpHost,
            }).subscribe(token => {
                url.searchParams.set("token", token)
                subscriber.next(url);
                subscriber.complete();
            })
        })
    }
}

@NgModule({
    imports: [
        IconsModule,
        CostumerAuthModule,
        TranslocoCoreModule,
        AuthModule.forRoot({
            domain: environment.auth.domain,
            clientId: environment.auth.clientId,
            audience: environment.client.httpHost,
            cacheLocation: "localstorage",
            httpInterceptor: {
                allowedList: [
                    {
                        uri: environment.client.httpHost + "/*",
                        tokenOptions: {
                            audience: environment.client.httpHost
                        }
                    }
                ]
            },
        }),
        LibCostumerClientModule.forRoot({
            http: {
                host: environment.client.httpHost,
                interceptor: AuthHttpInterceptor,
            },
            ws: {
                host: environment.client.wsHost,
                authInterceptor: CostumerWsAuthInterceptor
            },
        }),
    ]
})
export class CoreModule {
    /**
     * Constructor
     */
    constructor(
        @Optional() @SkipSelf() parentModule?: CoreModule
    ) {
        // Do not allow multiple injections
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
        }
    }
}
