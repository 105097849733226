import { NgIf } from "@angular/common";
import { NgModule } from '@angular/core';
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ToggleThemeComponent } from "./toggle-theme.component";

@NgModule({
    declarations: [
        ToggleThemeComponent,
    ],
    imports: [
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        NgIf
    ],
    exports: [
        ToggleThemeComponent,
    ]
})
export class ToggleThemeModule {
}
