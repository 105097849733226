<!-- Button -->
<button *ngIf="usernameInside; else usernameOutside"
        mat-icon-button
        [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img class="w-7 h-7 rounded-full"
             *ngIf="showAvatar && user.avatar"
             [src]="user.avatar">
        <mat-icon *ngIf="!showAvatar || !user.avatar"
                  [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    </span>
</button>

<ng-template #usernameOutside>
	<div class="username-outside-wrapper"
	     [ngClass]="{'hey': userActions.closed | async}"
	     [matMenuTriggerFor]="userActions">
		<div class="username-outside-item">
			<span class="username-outside-label">{{userActions.closed.asObservable() | async}}</span>
			<span class="username-outside-label">{{user.email}}</span>
			<mat-icon *ngIf="!showAvatar || !user.avatar"
			          class="username-outside-icon"
			          [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
		</div>
	</div>
</ng-template>
<mat-menu [xPosition]="'before'"
          #userActions="matMenu">
	<ng-container *ngIf="usernameInside">
		<button mat-menu-item>
			<span class="flex flex-col leading-none">
				<span>Signed in as</span>
				<span class="mt-1.5 text-md font-medium">{{user.email}}</span>
			</span>
		</button>
		<mat-divider class="my-2"></mat-divider>
	</ng-container>
	<button mat-menu-item
	        (click)="signOut()">
		<mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
		<span>Sign out</span>
	</button>
</mat-menu>
