import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { UserService } from '@app-costumer/core/user/user.service';
import { AuthService } from "@auth0/auth0-angular";
import { Observable } from 'rxjs';

@Injectable()
export class CostumerAuthService {
    private _currentDomain: string;

    constructor(
        private _userService: UserService,
        private _auth0: AuthService,
        private _router: Router
    ) {
        this._currentDomain = new URL(window.location.href).origin;
    }

    signIn(): Observable<any> {
        return this._auth0.loginWithRedirect({
            redirect_uri: this._currentDomain + "/signed-in-redirect"
        })
    }

    signOut(): Observable<any> {
        return new Observable<any>(subscriber => {
            this._auth0.logout({
                returnTo: this._currentDomain + "/signed-out-redirect"
            });
            subscriber.complete();
        })
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        return this._auth0.isAuthenticated$;
    }
}
