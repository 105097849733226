<ng-container *ngIf="config.scheme == 'dark'">
	<button mat-icon-button
	        [matTooltip]="'Toggle theme'"
	        (click)="setScheme('light')">
		<mat-icon [svgIcon]="'heroicons_solid:moon'"></mat-icon>
	</button>
</ng-container>
<ng-container *ngIf="config.scheme == 'light'">
	<button mat-icon-button
	        [matTooltip]="'Toggle theme'"
	        (click)="setScheme('dark')">
		<mat-icon [svgIcon]="'heroicons_solid:sun'"></mat-icon>
	</button>
</ng-container>


