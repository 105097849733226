import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { NavigationService } from '@app-costumer/core/navigation/navigation.service';
import { UserService } from '@app-costumer/core/user/user.service';
import { LibCostumerClientWs } from "@lib-costumer-client/ws-client";
import { forkJoin, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any> {

    constructor(
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _ws: LibCostumerClientWs
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        // Fork join multiple API endpoint calls to wait all of them to finish
        return forkJoin([
            this._navigationService.get(),
            this._userService.get(),
            this._ws.connect(),
        ])
    }
}
