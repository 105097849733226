import { Route } from '@angular/router';
import { InitialDataResolver } from '@app-costumer/app.resolvers';
import { CostumerAuthGuard } from "@app-costumer/core/auth/guards/costumer-auth.guard";
import { CostumerNoAuthGuard } from "@app-costumer/core/auth/guards/costumer-no-auth.guard";
import { LayoutComponent } from '@app-costumer/layout/layout.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Main costumer page is a landing page
    // Landing routes
    {path: '', pathMatch: 'full', redirectTo: 'account'},

    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'account'},
    {path: 'signed-out-redirect', pathMatch: 'full', redirectTo: 'landing'},

    // Auth routes for guests
    {
        path: 'landing',
        canActivate: [CostumerNoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./modules/landing/home/home.module')
                    .then(m => m.LandingHomeModule)
            },
        ]
    },

    // Costumer routes
    {
        path: '',
        canActivate: [CostumerAuthGuard],
        canActivateChild: [CostumerAuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'account',
                loadChildren: () => import('./modules/costumer/account/account.module').then(m => m.AccountModule)
            },
        ]
    }
];
