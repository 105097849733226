import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from '@angular/core';
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { RouterModule } from "@angular/router";
import { ToggleThemeModule } from "@app-costumer/layout/common/toggle-theme/toggle-theme.module";
import { UserModule } from "@app-costumer/layout/common/user/user.module";
import { CostumerLayoutComponent } from "@app-costumer/layout/layouts/custom/costumer/costumer.component";
import { FuseLoadingBarModule } from "@lib-fuse/components/loading-bar";
import { FuseNavigationModule } from "@lib-fuse/components/navigation";

@NgModule({
    declarations: [
        CostumerLayoutComponent,
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        FuseLoadingBarModule,
        FuseNavigationModule,
        ToggleThemeModule,
        RouterModule,
        UserModule,
    ],
    exports: [
        CostumerLayoutComponent,
    ]
})
export class CostumerLayoutModule {
}
