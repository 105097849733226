import { CostumerEnvironment } from "./interface";

export const environment: CostumerEnvironment = {
    production: false,
    client: {
        httpHost: "https://api.dev.dig-dev.net",
        wsHost: "wss://ws.dev.dig-dev.net",
    },
    auth: {
        clientId: "73JwyOOFQ3Ss9F8y61UE55GGoBRoYw9x",
        domain: "auth.dig-dev.net"
    }
};
