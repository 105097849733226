import { NgModule } from '@angular/core';
import { LayoutComponent } from '@app-costumer/layout/layout.component';
import { CostumerLayoutModule } from "@app-costumer/layout/layouts/custom/costumer/costumer.module";
import { EmptyLayoutModule } from '@app-costumer/layout/layouts/empty/empty.module';
import { SharedModule } from '@app-costumer/shared/shared.module';

const layoutModules = [
    // Empty
    EmptyLayoutModule,
    //Custom
    CostumerLayoutModule,
];

@NgModule({
    declarations: [
        LayoutComponent
    ],
    imports: [
        SharedModule,
        ...layoutModules
    ],
    exports: [
        LayoutComponent,
        ...layoutModules
    ]
})
export class LayoutModule {
}
