import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@app-costumer/core/user/user.types';
import { AuthService } from "@auth0/auth0-angular";
import { first, map, Observable, ReplaySubject, tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _auth0: AuthService
    ) {
    }

    set user(value: User) {
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    get(): Observable<User> {
        return this._auth0.user$.pipe(
            first(),
            map(user => {
                return <User>{
                    email: user.email,
                    avatar: 'assets/images/avatars/brian-hughes.jpg',
                    name: user.name,
                    status: 'online',
                    id: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
                }
            }),
            tap((user:User) => {
                this._user.next(user);
            })
        )
    }

    update(user: User): Observable<any> {
        return this._httpClient.patch<User>('api/common/user', {user}).pipe(
            map((response) => {
                this._user.next(response);
            })
        );
    }
}
